import getChartColorsArray from "components/Common/ChartsDynamicColor";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody } from "reactstrap";

const ProviderChart = ({ dataColors, KPI, iconClass }) => {
  var barChartColor = getChartColorsArray(dataColors);
  const series = [
    {
      data: [380, 430, 450, 475, 550, 584, 780, 1100, 1220, 1365],
    },
  ];
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: barChartColor,
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: KPI?.data?.map((item) => item.first_name),
    },
  };

  const colors = JSON.parse(dataColors).map((color) =>
    color.replace("--bs", "")
  );
  return (
    <Card className="mini-stats-wid">
      <CardBody>
        <h4 className="card-title mb-4">{KPI?.name}</h4>
        <div>
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height="350"
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default ProviderChart;
