export const stakeholderPaths = {
  reinsurer: "/reinsurer",
  physician: "/physician",
  payer: "/payer",
  healthcare_providers: "/healthcare_provider",
  beneficiaryList: "/medical-members",
  membersSmilarity: "/medical-members/similarity",
  broker: "/broker",
  customers: "/customers",
  customersSmilarity: "/customers/similarity",
  agents: "/agents",
};
