import React, { Fragment, useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import {
  AddMessage,
  getConversations,
  showConverstation,
  updateConversation,
} from "helpers/General/conversation_helper"
import { useSelector } from "react-redux"
import { isEmpty } from "lodash"
import ChatSidebar from "pages/Claims/ClaimForm/Conversation/ChatSidebar"
import moment from "moment"
import { NotificationMessage, apiErrorrHandler } from "utils"

const ChatModal = ({ show, onCloseClick }) => {
  const [error, setError] = useState("")
  const [messageBox, setMessageBox] = useState(null)
  const [message, setMessage] = useState("")
  const [conversation, setConversation] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [IsLoading, setIsLoading] = useState("")
  const [name, setName] = useState("")
  const [newName, setNewName] = useState("")
  const [selectedConversation, setSelectedConversation] = useState(null)
  const module_id = useSelector((state) => state?.Module?.id)
  const resourceId = location.pathname.split("/")[3]
  const [conversations, setConversations] = useState([])
  const [accessType, setAccessType] = useState("") //  access_type_id state
  const myAccessType = JSON.parse(localStorage.getItem("authUser"))?.access_type
    ?.id

  // update accessType value from the child component - sidebar
  const updateAccessType = (newAccessType) => {
    setAccessType(newAccessType)
  }

  //get all conversations of same resource id and module id
  const getAllConversations = async () => {
    let filteredConversations = []
    setIsLoading(true)
    try {
      const response = await getConversations({
        per_page: 1000,
        page: 1,
        module_id,
        resource_id: resourceId,
        access_type_id: myAccessType !== 1 ? myAccessType : null, //if user access type 1 (tpa) get all convs, else get convs of this user access type
      })
      console.log(response.items)
      //filter Conversations based on resource_id , module_id
      {
        filteredConversations = response.items.filter(
          (item) =>
            item.resource_id == resourceId && item?.module?.id === module_id
        )
      }

      setConversations(filteredConversations)
      if (filteredConversations?.length > 0) {
        setSelectedConversation(filteredConversations[0]?.id)
      }
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  //get all conversations depending on resourceId
  useEffect(() => {
    if (resourceId) {
      getAllConversations()
    }
  }, [resourceId])

  //add messagge to ui and db
  const sendMsgSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await AddMessage(selectedConversation, {
        message,
        user_id: JSON.parse(localStorage.getItem("authUser"))?.id,
      })
      setMessage("")
      getConversation(selectedConversation)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      setError(errorMessage)
    }
  }

  //update conversation name in db & ui
  const onUpdateSubmit = async (e) => {
    e.preventDefault()
    if (newName.trim() !== "") {
      try {
        const updatedConv = await updateConversation(selectedConversation, {
          name: newName,
        })
        setName(newName)
        setNewName("")
        setIsOpen(false)
        getConversation(selectedConversation)
        setConversations((prev) => {
          return prev.map((item) =>
            item.id === updatedConv.item.id ? updatedConv.item : item
          )
        })
        // refetchConversations()
      } catch (error) {}
    }
    setNewName("")
  }

  //show conversation for each one
  const getConversation = async (id) => {
    setIsLoading(true)
    try {
      const response = await showConverstation(id)

      setConversation(response.item)
      console.log(conversation)
      setNewName(response.item?.name)
      setError("")
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  //get chat of selected conversation
  useEffect(() => {
    if (selectedConversation) {
      getConversation(selectedConversation)
    }
  }, [selectedConversation])

  //message of my user id
  const isMyMessage = (userId) => {
    const user = JSON.parse(localStorage.getItem("authUser"))?.id
    return user === userId ? true : false
  }

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000
    }
  }

  useEffect(() => {
    if (!isEmpty(conversation?.messages)) scrollToBottom()
  }, [conversation])

  return (
    <Modal
      size="xl"
      isOpen={show}
      toggle={onCloseClick}
      className="modal-fullscreen"
    >
      {/* Modal header */}
      <div className="modal-header bg-light bg-opacity-50" id="myModalLabel">
        {/* Modal Header Title */}
        <h5 className="modal-title mt-0 ">
          {conversation ? (
            conversation.name
          ) : (
            <p className="text-danger fw-bold">
              Please Press "Add Conversation" Button To Start Chatting
            </p>
          )}
        </h5>
        {/* show update icon if there is a conversation */}
        {conversation ? (
          <ul className="list-inline user-chat-nav text-end mb-0">
            <li className="list-inline-item d-none d-sm-inline-block">
              <Button
                onClick={() => setIsOpen(true)}
                className="bg-transparent  border-0 text-black shadow-none d-flex align-items-center justify-content-center"
                style={{ paddingRight: "50px" }}
              >
                <i className="bx bx-cog font-size-17" />
              </Button>
              <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
                <ModalHeader>Update Conversation name</ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={onUpdateSubmit}
                    className="d-flex flex-column"
                  >
                    <Input
                      type="text"
                      placeholder="Update name"
                      value={newName}
                      onChange={(e) => {
                        setNewName(e.target.value)
                      }}
                      className="ps-2 pr-4 w-100 border-1"
                    />
                    <Button
                      type="submit"
                      className="mt-3 btn-blue align-self-end"
                      disabled={!newName}
                    >
                      Update
                    </Button>
                  </Form>
                </ModalBody>
              </Modal>
            </li>
          </ul>
        ) : null}

        {/* close button on the top right section */}
        <button
          aria-label="Close Modal"
          type="button"
          onClick={onCloseClick}
          className="btn-close position-absolute end-0 top-0 m-3"
        ></button>
      </div>

      {/* Conversation container */}
      <React.Fragment>
        <div className="page-content p-2">
          <Container fluid>
            <Row>
              <Col lg="12">
                <div className="d-lg-flex">
                  {/* left sidebar */}
                  <div className="me-lg-0">
                    <ChatSidebar
                      setSelectedConversation={setSelectedConversation}
                      refetchConversations={() => getAllConversations()}
                      conversations={conversations}
                      updateAccessType={updateAccessType}
                    />
                  </div>
                  {/* chat  */}
                  <div className="w-100 user-chat ">
                    <Card>
                      <div>
                        <div className="chat-conversation p-3 ">
                          <ul className="list-unstyled">
                            <PerfectScrollbar
                              style={{ height: "479px" }}
                              containerRef={(ref) => setMessageBox(ref)}
                            >
                              {conversation &&
                                conversation?.messages.length > 0 &&
                                conversation?.messages
                                  ?.sort((a, b) => {
                                    return a.id - b.id
                                  })
                                  ?.map((msg, index) => (
                                    <Fragment key={msg?.id}>
                                      <div
                                        className={`d-flex ${
                                          isMyMessage(msg?.user?.id)
                                            ? "justify-content-start flex-row-reverse me-3"
                                            : " justify-content-start"
                                        }  gap-1`}
                                      >
                                        <div
                                          className="card bg-blue bg-opacity-10"
                                          style={
                                            isMyMessage(msg?.user?.id)
                                              ? {
                                                  borderBottomRightRadius:
                                                    "0rem",
                                                }
                                              : {
                                                  borderBottomLeftRadius:
                                                    "0rem",
                                                }
                                          }
                                        >
                                          <div className="card-body p-2">
                                            <h4 className="text-blue">
                                              {isMyMessage(msg?.user?.id)
                                                ? "You"
                                                : msg?.user?.name}
                                            </h4>
                                            <p className="mb-1">
                                              {msg.message}
                                            </p>
                                            <p className="mb-0">
                                              <i className=" bx bx-time font-size-12 me-1" />
                                              <span>
                                                {/* {moment(msg?.created_at).format(
                                                  "DD-MM-YYYY hh:mm"
                                                )} */}
                                                {moment(msg?.created_at).format(
                                                  "DD-MM-YYYY "
                                                )}
                                                <span className="time text-danger">
                                                  {moment(
                                                    msg?.created_at
                                                  ).format("hh:mm")}
                                                </span>
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                        {isMyMessage(msg?.user?.id) ? (
                                          <div className="conversation-list">
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                tag="button"
                                                className="dropdown-toggle border-0 bg-transparent"
                                              >
                                                <i className="bx bx-dots-vertical-rounded" />
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                <DropdownItem tag="button">
                                                  Delete
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </div>
                                        ) : null}
                                      </div>
                                    </Fragment>
                                  ))}
                            </PerfectScrollbar>
                          </ul>
                        </div>
                        {/* show input & send button only if there is a conversation */}
                        {conversation ? (
                          <div className="p-3 chat-input-section ">
                            <Form onSubmit={sendMsgSubmit}>
                              <div className="d-flex align-items-center gap-4">
                                <Input
                                  className="rounded-pill ps-3 bg-blue bg-opacity-10 border-0"
                                  type="text"
                                  placeholder="Enter message..."
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                />
                                <Button
                                  className="btn btn-blue w-md rounded-pill"
                                  type="submit"
                                  disabled={!message}
                                >
                                  <span className="d-none d-sm-inline-block me-2">
                                    Send
                                  </span>
                                  <i className="bx bx-send font-size-13 "></i>
                                </Button>
                              </div>
                            </Form>
                          </div>
                        ) : null}
                      </div>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </Modal>
  )
}

export default ChatModal
