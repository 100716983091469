/* Paths */
import { motorPaths } from "common/data/routePaths"
import { lazy } from "react"

/*
Components
*/

// workshops
const Workshops = lazy(() => import("pages/Motors/Workshops"))
const workshopsForms = lazy(() =>
  import("pages/Motors/Workshops/workshopsForms")
)
// motonetwokrs
const MotoNetworks = lazy(() => import("pages/Motors/MotoNetworks"))
const MotoNetworksForms = lazy(() =>
  import("pages/Motors/MotoNetworks/MotoNetworksForms")
)
// agentMotoCommissions
const AgentMotoCommissions = lazy(() =>
  import("pages/Motors/AgentMotoCommissions")
)
const AgentMotoCommissionsForms = lazy(() =>
  import("pages/Motors/AgentMotoCommissions/AgentMotoCommissionsForms")
)

// moto Insured
const MotoInsured = lazy(() => import("pages/Motors/MotoInsured"))
const MotoInsuredForm = lazy(() =>
  import("pages/Motors/MotoInsured/MotoInsuredForms")
)

// moto policies
const MotoPolicies = lazy(() => import("pages/Motors/MotoPolicies"))
// moto products
const MotoProducts = lazy(() => import("pages/Motors/MotoProducts"))
// moto experts
const MotoExperts = lazy(() => import("pages/Motors/Experts"))
// experts forms
const MotoExpertsForms = lazy(() => import("pages/Motors/Experts/ExpertsForms"))
// fnol
const FNOL = lazy(() => import("pages/Motors/FNOL"))
const FNOLForms = lazy(() => import("pages/Motors/FNOL/FNOLForms"))
// fnol validation
const FNOLValidation = lazy(() => import("pages/Motors/FNOL/Validation"))
// Moto Tob
const MotoTOBTemplates = lazy(() => import("pages/Motors/MotorTOB"))
const MotoTOBTemplatesForms = lazy(() =>
  import("pages/Motors/MotorTOB/TOBTemplatesForms")
)
const motoDrivers = lazy(() => import("pages/Motors/Drivers"))
const motoVehicles = lazy(() => import("pages/Motors/Vehicle"))

const {
  workShops,
  motorNetworks,
  agentMotoCommissions,
  motoInsured,
  motoPolicies,
  motoProducts,
  motoExperts,
  fnolForm,
  fnolValidation,
  motoTOB,
  motorDrivers,
  motorVehicles,
} = motorPaths

export const MotorsRoutes = [
  // Workshops
  { path: workShops, component: Workshops },
  { path: `${workShops}/add`, component: workshopsForms },
  { path: `${workShops}/edit/:id`, component: workshopsForms },
  { path: `${workShops}/view/:id`, component: workshopsForms },

  // MotoNewtorks
  { path: motorNetworks, component: MotoNetworks },
  { path: `${motorNetworks}/add`, component: MotoNetworksForms },
  { path: `${motorNetworks}/edit/:id`, component: MotoNetworksForms },
  { path: `${motorNetworks}/view/:id`, component: MotoNetworksForms },

  // AgentMotoCommissions
  { path: agentMotoCommissions, component: AgentMotoCommissions },
  { path: `${agentMotoCommissions}/add`, component: AgentMotoCommissionsForms },
  {
    path: `${agentMotoCommissions}/edit/:id`,
    component: AgentMotoCommissionsForms,
  },
  {
    path: `${agentMotoCommissions}/view/:id`,
    component: AgentMotoCommissionsForms,
  },

  // moto insured
  { path: motoInsured, component: MotoInsured },
  { path: `${motoInsured}/add`, component: MotoInsuredForm },
  { path: `${motoInsured}/edit/:id`, component: MotoInsuredForm },
  { path: `${motoInsured}/view/:id`, component: MotoInsuredForm },

  // moto policies
  { path: motoPolicies, component: MotoPolicies },
  // moto products
  { path: motoProducts, component: MotoProducts },
  // moto experts
  { path: motoExperts, component: MotoExperts },
  { path: `${motoExperts}/form`, component: MotoExpertsForms },
  { path: `${motoExperts}/form/:id`, component: MotoExpertsForms },
  { path: `${motoExperts}/view/:id`, component: MotoExpertsForms },
  // FNOL Form
  { path: fnolForm, component: FNOL },
  // FNOL Form Forms
  { path: `${fnolForm}/form`, component: FNOLForms },
  { path: `${fnolForm}/form/:id`, component: FNOLForms },
  { path: `${fnolForm}/view/:id`, component: FNOLForms },
  // FNOL Validation Form
  { path: fnolValidation, component: FNOLValidation },
  // moto TOB
  { path: motoTOB, component: MotoTOBTemplates },
  { path: `${motoTOB}/add`, component: MotoTOBTemplatesForms },
  { path: `${motoTOB}/edit/:id`, component: MotoTOBTemplatesForms },
  { path: `${motoTOB}/view/:id`, component: MotoTOBTemplatesForms },
  // moto drivers
  { path: motorDrivers, component: motoDrivers },
  // moto vehicles
  { path: motorVehicles, component: motoVehicles },
]
