import * as url from "../url_helper"
import { request } from "../api_helper"

export const getAllAccessTypes = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  view,
}) =>
  request({
    url: url.ACCESSTYPES,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by,
      sort_type,
      search_keys,
      search_values,
      search_value,
      view,
    },
  })
