import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

import {
  claimDashboardID,
  dashboardsID,
  payerDashboardID,
} from "utils/modulesIds";
import PageBreadCrumbAndTitle from "components/TP/Common/General/PageBreadCrumbAndTitle";
import {
  CardUser,
  FOBChart,
  GaugeChart,
  KPIInfoCard,
  PieChart,
} from "components/TP/dashboards";
import { NotificationMessage, apiErrorrHandler } from "utils";
import { useSelector } from "react-redux";
import { APIDateFormat, CompanyName } from "constants/TPA";
import moment from "moment";
import MultiSkeleton from "components/TP/Common/MultiSkeleton";
import dayjs from "dayjs";
import { ModalSpinner } from "components/TP/Common/Forms";
import ClaimsNumber from "components/TP/dashboards/ClaimsNumber";
import ProviderChart from "components/TP/dashboards/ProviderChart";
import { showDashboard, showKPI } from "helpers/General/dashboards_helper";

const PayerDashboard = (props) => {
  const [dashboardKPIs, setDashboardKPIs] = useState([]);
  const [dateRange, setDateRange] = useState({
    start_date: dayjs().add(-7, "d").format(APIDateFormat),
    end_date: dayjs().format(APIDateFormat),
  });
  const [isLoading, setIsLoading] = useState(false);

  const { module, loading } = useSelector((state) => ({
    module: state.Sidebar?.modules
      ?.find((item) => item.id === dashboardsID)
      ?.sub_modules?.find((item) => item.id === payerDashboardID),
    loading: state.Sidebar?.loading,
  }));

  const dataColors =
    '["--bs-primary", "--bs-success", "--bs-danger" , "--bs-secondary"]';

  //   const getDashboard = async (dashboardId, dates) => {
  //     setIsLoading(true);
  //     try {
  //       const response = await showDashboard(dashboardId, dates);
  //       setDashboardKPIs(response.item);
  //     } catch (error) {
  //       const errorMessage = apiErrorrHandler(error);
  //       NotificationMessage("Error", errorMessage);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   const slug =
  //     "claims-not-unused-not-declined-payers-top-providers-by-total-claimed-amount";
  const getKPI = async (slug, data) => {
    setIsLoading(true);
    try {
      const response = await showKPI({
        // slug: "claims-not-unused-not-declined-payers-top-providers-by-total-claimed-amount",
        start_date: moment(dateRange.start_date).format(APIDateFormat),
        end_date: moment(dateRange.end_date).format(APIDateFormat),
        condition_keys: [51],
        condition_values: [51],
      });
      setDashboardKPIs(response.item);
    } catch (error) {
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getKPI({
      start_date: moment(dateRange.start_date).format(APIDateFormat),
      end_date: moment(dateRange.end_date).format(APIDateFormat),
      condition_keys: [51],
      condition_values: [51],
    });
  }, [dateRange.start_date, dateRange.end_date]);

  console.log(dashboardKPIs);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageBreadCrumbAndTitle title={module?.title} />
          {/* Rounded Corners White Card and Table inside it */}
          <Row>
            {/* Welcome User Card */}
            <CardUser setDateRange={setDateRange} dateRange={dateRange} />
            {/* Loading State */}
            {isLoading ? <ModalSpinner color="primary" /> : null}
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              {
                !isLoading && (
                  // dashboardKPIs.filter(
                  //   (kpi) =>
                  //     kpi.slug ===
                  //     "claims-not-unused-not-declined-payers-top-providers-by-total-claimed-amount"
                  // ).length > 0 &&
                  // dashboardKPIs
                  //   .filter(
                  //     (kpi) =>
                  //       kpi.slug ===
                  //       "claims-not-unused-not-declined-payers-top-providers-by-total-claimed-amount"
                  //   )
                  //   .map((kpi) => (
                  <ProviderChart
                    key={dashboardKPIs.slug}
                    KPI={dashboardKPIs}
                    dataColors={dataColors}
                  />
                )
                //   ))
              }
            </Col>
            <Col md={6}>
              <ProviderChart dataColors={dataColors} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <GaugeChart dataColors={dataColors} />
            </Col>
            <Col md={6}>
              <PieChart dataColors={dataColors} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

// PayerDashboard.propTypes = {
//   t: PropTypes.any,
// }

export default withTranslation()(PayerDashboard);
