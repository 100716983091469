import React, { Fragment, useState } from "react"

//i18n
import { withTranslation } from "react-i18next"

// moment
import moment from "moment"

// UI Components
import {
  FormModal,
  ModalSpinner,
  SearchSelect,
  SearchTree,
} from "../../../components/TP/Common/Forms"

import { Alert } from "reactstrap"

// utility function for error
import { fieldFinder, isInputInvalid, NotificationMessage } from "utils/"
import { useSelector } from "react-redux"
import { showPredefined } from "helpers/Settings/predefineds/predefineds_herlper"
import PopUpFormTitle from "components/TP/Common/General/PopUpFormTitle"
import { claimAdmissionDetailsId, claimDetailsModuleId } from "utils/modulesIds"
import { getAllUsers } from "helpers/Settings/Users/users_helper"

const ChangeClaimStatusModal = ({
  type,
  validation,
  isOpen,
  setIsOpen,
  OnConfirm,
  isSaving,
}) => {
  // states
  const [error, setError] = useState("")
  const { fields: inputs, loading } = useSelector(state => ({
    fields: state?.Module?.subModules
      ?.find(item => item.id === claimDetailsModuleId)
      ?.sub_modules?.find(item => item.id === claimAdmissionDetailsId)?.fields,
    loading: state?.Module?.loading,
  }))

  return (
    <FormModal
      show={isOpen}
      onCloseClick={() => setIsOpen(false)}
      onSaveClick={() => {
        OnConfirm()
        setIsOpen(false)
      }}
      // modalTitle={t(`${whiteList ? "WhiteList" : "BlackList"} Details`)}
      modalTitle={`${type} Claim`}
      isSaving={isSaving}
    >
      {loading ? (
        <ModalSpinner />
      ) : (
        <form onSubmit={e => e.preventDefault()}>
          {/* API Error Message For Re-Insurer Module */}

          {error ? <Alert color="danger">{error}</Alert> : null}

          {inputs && inputs.length > 0 && (
            <Fragment>
              {/* Re-Insurer Name Label & Input  */}
              {type === "forward" && (
                <SearchSelect
                  id="711"
                  name="declined_reason_id"
                  inputField={{
                    ...fieldFinder(inputs, 711),
                    predefined_value: null,
                  }}
                  value={validation.values.declined_reason_id}
                  onChange={validation.setFieldValue}
                  onBlur={validation.setFieldTouched}
                  mappingValue="first_name"
                  mappingLabel="first_name"
                  generateCustomLabels={item =>
                    `${item.first_name} ${item.last_name}`
                  }
                  getOptionsData={getAllUsers}
                  urlAddOns={{
                    access_type_id: "1",
                  }}
                  generateDisabledOptions={option => option.is_active === 0}
                  invalid={isInputInvalid(validation, "declined_reason_id")}
                  error={validation.errors.declined_reason_id}
                  wrapperClassNames="mb-3"
                />
              )}
              {type === "declined" && (
                <SearchTree
                  id="711"
                  name="declined_reason_id"
                  inputField={fieldFinder(inputs, 711)}
                  value={validation.values.declined_reason_id || null}
                  onChange={validation.setFieldValue}
                  onBlur={validation.setFieldTouched}
                  fieldNames={{
                    children: "children",
                    label: "name",
                    value: "id",
                  }}
                  getTreeData={showPredefined}
                  recordId={fieldFinder(inputs, 711)?.predefined_value?.id}
                  invalid={isInputInvalid(validation, "declined_reason_id")}
                  error={validation.errors.declined_reason_id}
                  wrapperClassNames="mb-3"
                />
              )}
              {type === "pending" && (
                <SearchTree
                  id="710"
                  name="pending_reason_id"
                  inputField={fieldFinder(inputs, 710)}
                  value={validation.values.pending_reason_id || null}
                  onChange={validation.setFieldValue}
                  onBlur={validation.setFieldTouched}
                  fieldNames={{
                    children: "children",
                    label: "name",
                    value: "id",
                  }}
                  getTreeData={showPredefined}
                  recordId={fieldFinder(inputs, 710)?.predefined_value?.id}
                  invalid={isInputInvalid(validation, "pending_reason_id")}
                  error={validation.errors.pending_reason_id}
                  wrapperClassNames="mb-3"
                />
              )}
              {/* When Click unprossed (change status to received) */}
              {type === "unprocessed" && (
                <SearchTree
                  id="787"
                  name="unprocessed_reason_id"
                  inputField={fieldFinder(inputs, 787)}
                  value={validation.values.unprocessed_reason_id || null}
                  onChange={validation.setFieldValue}
                  onBlur={validation.setFieldTouched}
                  fieldNames={{
                    children: "children",
                    label: "name",
                    value: "id",
                  }}
                  getTreeData={showPredefined}
                  recordId={fieldFinder(inputs, 787)?.predefined_value?.id}
                  invalid={isInputInvalid(validation, "unprocessed_reason_id")}
                  error={validation.errors.unprocessed_reason_id}
                  wrapperClassNames="mb-3"
                />
              )}
            </Fragment>
          )}
        </form>
      )}
    </FormModal>
  )
}

export default withTranslation()(ChangeClaimStatusModal)
