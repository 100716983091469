import * as url from "../url_helper";
import { request } from "../api_helper";

// get Dashboard
//url/{id}
export const showDashboard = (dashboardId, data) =>
  request({ url: `${url.DASHBOARDS}/${dashboardId}`, method: "POST", data });

export const showKPI = (data) =>
  request({
    url: `${url.KPIS}/claims-grouped-by-type-and-status`,
    method: "POST",
    data,
  });
