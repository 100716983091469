import { addConversation } from "helpers/General/conversation_helper"
import React, { Fragment, useState } from "react"

import { Button, Form, Input, Modal, ModalBody, ModalHeader } from "reactstrap"
import { NotificationMessage, apiErrorrHandler, isInputInvalid } from "utils"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { FormControll, SearchTree } from "components/TP/Common/Forms"
import { getAllAccessTypes } from "helpers/General/accessTypes_helper"
import { useFormik } from "formik"
import { staticLabels } from "common/data"
import * as URLs from "helpers/url_helper"

const ChatSidebar = ({
  setSelectedConversation,
  refetchConversations,
  conversations,
  updateAccessType,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState("")
  const [newConversationName, setNewConversationName] = useState("")
  const location = useLocation()
  const resourceId = location.pathname.split("/")[3]
  const module_id = useSelector((state) => state?.Module?.id)
  const [childAccessType, setChildAccessType] = useState("") // state for child component

  const OnAccessTypeChange = () => {
    // Update the accessType in the parent component
    updateAccessType(childAccessType)
  }
  OnAccessTypeChange()

  //value of conversation name input
  const nameInputChange = (e) => {
    setNewConversationName(e.target.value)
  }

  // add new conversation to db
  const onAddSubmit = async (e) => {
    e.preventDefault()
    const AddnewConversation = async () => {
      try {
        await addConversation({
          name: newConversationName,
          module_id,
          resource_id: resourceId,
          access_type_id: childAccessType, //id of selected accesstype from dropdown
        })
        setError("")
        setIsOpen(false)
        refetchConversations()
      } catch (error) {
        const errorMessage = apiErrorrHandler(error)
        NotificationMessage("Error", errorMessage)
      }
    }
    setNewConversationName("")
    AddnewConversation()
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      access_type_id: "",
      // conversation_name: newConversationName,
    },
  })
  return (
    <Fragment>
      <div>
        <Button
          className="btn btn-blue rounded-0"
          type="button"
          onClick={() => setIsOpen(true)}
          style={{
            padding: "8px 23px",
            whiteSpace: "nowrap",
          }}
        >
          Add Conversation
        </Button>
        <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
          <ModalHeader>Add new conversation</ModalHeader>
          <ModalBody>
            <Form onSubmit={onAddSubmit} className="d-flex flex-column">
              <SearchTree
                name="access_type_id"
                label={staticLabels.chatAccessType}
                placeholder="Please Choose Access Type"
                // inputField={fieldFinder(inputs, 23)}
                value={validation.values.access_type_id || undefined}
                onChange={validation.setFieldValue}
                onBlur={validation.setFieldTouched}
                getTreeData={getAllAccessTypes}
                fieldNames={{
                  children: "children",
                  label: "name",
                  value: "id",
                }}
                url={URLs.ACCESSTYPES}
                urlQuery={{
                  // search_keys: "name",
                  // search_values: "access",
                  is_featured: 1,
                }}
                invalid={isInputInvalid(validation, "access_type_id")}
                // error={validation.errors.access_type_id}
                wrapperClassNames="mb-3"
                onSelectData={(data) => {
                  if (data?.id) {
                    setChildAccessType(data?.id)
                    setNewConversationName(data?.name)
                  }
                }}
              />
              <FormControll
                type="text"
                label={staticLabels.conversationName}
                placeholder="Enter conversation name"
                value={newConversationName}
                onChange={nameInputChange}
                className="ps-2 pr-4 w-100 border-1 mb-3"
              />
              <Button
                type="submit"
                className="mt-3 btn-blue align-self-end"
                disabled={
                  !newConversationName || !validation.values.access_type_id
                }
              >
                Add
              </Button>
            </Form>
          </ModalBody>
        </Modal>
        <ul
          className="list-unstyled chat-list bg-light bg-opacity-50"
          id="recent-list"
        >
          <PerfectScrollbar style={{ height: "560px" }}>
            {conversations?.length > 0 &&
              conversations.map((conversation) => (
                <li key={conversation.id}>
                  <Link
                    to="#"
                    onClick={() => setSelectedConversation(conversation.id)}
                  >
                    <div className="d-flex">
                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="font-size-13  mb-1">
                          {conversation.name}
                        </h5>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
          </PerfectScrollbar>
        </ul>
        {/* </Col>
        </Row> */}
      </div>
    </Fragment>
  )
}

export default ChatSidebar
