import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, REFRESH_TOKEN } from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  refreshTokenSuccess,
} from "./actions"

// import helpers functions
import {
  postEmailLogin,
  postLogout,
  postRefreshToken,
} from "helpers/AuthType/auth_helper"
import { apiErrorrHandler } from "utils/apiErrorHandler"

function* loginUser({ payload: { user, history, afterLoginCB } }) {
  try {
    const response = yield call(postEmailLogin, {
      email: user.email,
      password: user.password,
    })
    const {
      access_token,
      expires_in,
      token_type,
      user: authUser,
    } = response.item

    const expiredDate = new Date()
    expiredDate.setSeconds(expiredDate.getSeconds() + (expires_in - 300))
    localStorage.setItem("authUser", JSON.stringify(authUser))
    localStorage.setItem(
      "accessToken",
      JSON.stringify({ access_token, expires_in: expiredDate, token_type })
    )

    yield put(loginSuccess(response.item))
    if (afterLoginCB) {
      afterLoginCB()
    }
    history.push("/dashboard")
  } catch (error) {
    console.log(error)
    const errorMessage = apiErrorrHandler(error)
    yield put(apiError(errorMessage))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(postLogout)
    yield put(logoutUserSuccess(response))
    localStorage.removeItem("authUser")
    localStorage.removeItem("accessToken")
    localStorage.removeItem("TPACurrency")
    history.go(0)
  } catch (error) {
    const errorMessage = apiErrorrHandler(error)
    yield put(apiError(errorMessage))
    console.log(error)
  }
}
function* refreshToken() {
  try {
    const response = yield call(postRefreshToken)
    const { access_token, expires_in, token_type } = response.item
    yield put(refreshTokenSuccess(response.item))

    // if (localStorage.getItem("accessToken") !== null) {
    //   localStorage.setItem("accessToken", JSON.stringify(response.item))
    // }

    const expiredDate = new Date()
    expiredDate.setSeconds(expiredDate.getSeconds() + (expires_in - 300))
    localStorage.setItem(
      "accessToken",
      JSON.stringify({ access_token, expires_in: expiredDate, token_type })
    )

    console.log(response)
  } catch (error) {
    const errorMessage = apiErrorrHandler(error)

    yield put(apiError(errorMessage))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(REFRESH_TOKEN, refreshToken)
}

export default authSaga
