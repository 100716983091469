/* Paths */
import { reportPaths } from "common/data/routePaths";
import { lazy } from "react";
/*
Components
*/

const DynamicReports = lazy(() => import("pages/Reports/DynamicReports"));
const BlackListedReports = lazy(() =>
  import("pages/Reports/BlackListedReports")
);
const DueToExpiryReport = lazy(() => import("pages/Reports/DueToExpiryReport"));
const AgentComissionReports = lazy(() =>
  import("pages/Reports/AgentComissionReports")
);
const NotRenewedReports = lazy(() => import("pages/Reports/NotRenewedReport"));
const AgentPoliciesReports = lazy(() =>
  import("pages/Reports/AgentPoliciesReports")
);
const VolumeRebateReport = lazy(() =>
  import("pages/Reports/VolumeRebateReport")
);
const FeesByBeneficiary = lazy(() => import("pages/Reports/FeesByBeneficiary"));
const FeesByClaims = lazy(() => import("pages/Reports/FeesByClaims"));
// loss ratio
const LossRatio = lazy(() => import("pages/Reports/LossRatio"));
const {
  dynamicReport,
  blackListedReport,
  dueToExpirtyReport,
  agentComissionReport,
  notRenewedReports,
  agentPoliciesReports,
  volumeRebateReport,
  feesByBeneficiaryReport,
  feesByClaimsReport,
  lossRatio,
} = reportPaths;

export const ReportsRoutes = [
  // dynamic reports
  { path: dynamicReport, component: DynamicReports },
  // blacklisted reports
  { path: blackListedReport, component: BlackListedReports },
  // due to expiry reports
  { path: dueToExpirtyReport, component: DueToExpiryReport },
  // Agent Comission Reports reports
  { path: agentComissionReport, component: AgentComissionReports },
  // not renewed reports
  { path: notRenewedReports, component: NotRenewedReports },
  // agent policies reports
  { path: agentPoliciesReports, component: AgentPoliciesReports },
  // volume rebate report
  { path: volumeRebateReport, component: VolumeRebateReport },

  // fees by beneficiary report
  { path: feesByBeneficiaryReport, component: FeesByBeneficiary },
  // fees by claims report
  { path: feesByClaimsReport, component: FeesByClaims },
  // loss ratio report
  { path: lossRatio, component: LossRatio },
];
