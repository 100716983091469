import React, { useEffect, useState } from "react"
import { DatePickerInput, FormModal } from "components/TP/Common/Forms"
// translation
import { withTranslation } from "react-i18next"
// helper functions

import {
  calculatePremiums,
  getPremiums,
  savePremium,
} from "helpers/Production/Policies/beneficaries_helper"
import { NotificationMessage, apiErrorrHandler } from "utils"
import { TableSkeleton } from "components/Common"
import { Alert } from "reactstrap"
import { isInteger, isNaN, isNull } from "lodash"
import { formatedTodayData } from "utils/smallUtils"
import { stakeholderPaths } from "common/data/routePaths"
import { beneficaryinstallmentsId } from "utils/modulesIds"
import { useHistory } from "react-router-dom"

const PremiumModal = ({
  isPremiumModal,
  setIsPremiumModal,
  editable,
  beneficaryId,
  policy,
  principle,
  t,
}) => {
  const history = useHistory()
  const [error, setError] = useState("")
  const [discountError, setDiscountError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [premium, setPremium] = useState(null)
  const [tableHeader, setTableHeader] = useState([])
  const [effectiveDate, setEffectiveDate] = useState(
    policy && policy?.start_date ? policy.start_date : formatedTodayData()
  )

  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (editable) {
      setTableHeader([
        "Net Premium",
        "Gross Premium",
        "Discount",
        "Over Premium",
        "Issueing Fees",
        "Tax",
        "Total",
      ])
    } else {
      setTableHeader([
        "#",
        "Beneficiary",
        "Net Premium",
        "Gross Premium",
        "Discount",
        "Issueing Fees",
        "Over Premium",
        "Tax",
        "Total",
      ])
    }
  }, [editable])

  // save premium
  const handleSavePremium = async beneficaryId => {
    setIsSaving(true)
    try {
      const response = await savePremium(beneficaryId, {
        discount_amount:
          premium && premium.discount_amount ? premium.discount_amount : 0,
        adjustment_amount:
          premium && premium.adjustment_amount ? premium.adjustment_amount : 0,

        effective_date: effectiveDate ? effectiveDate : formatedTodayData(),
        issuing_fees:
          premium && premium.issuing_fees ? premium.issuing_fees : 0,
      })
      // calculateBeneficiaryPremiums(beneficaryId)

      // setPremium(response?.item)
      setPremium({
        ...response.item,
        discount_amount: response.item.discount_amount.toFixed(2),
        issuing_fees: "",
        sub_total:
          response.item.price +
          response.item.loading_amount +
          response.item.tpa_fees_amount +
          response.item.commission_amount +
          response.item.other_amount,
      })

      NotificationMessage("success", "Saved Successfully")
      setTimeout(() => {
        setIsPremiumModal(false)
        window.location.reload(false)
        // history.push(
        //   `${stakeholderPaths.beneficiaryList}/edit/${
        //     principle ? principle.id : beneficaryId
        //   }#${beneficaryinstallmentsId}`
        // )
        // history.push(`${stakeholderPaths.beneficiaryList}/edit/${beneficaryId}`)
      }, 2000)
      setError("")
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      setError(errorMessage)
    } finally {
      setIsSaving(false)
    }
  }

  // calculate Premium

  const calculateBeneficiaryPremiums = async beneficaryId => {
    setIsLoading(true)
    try {
      const response = await calculatePremiums({
        discount_amount:
          premium && premium.discount_amount ? premium.discount_amount : 0,
        adjustment_amount:
          premium && premium.adjustment_amount ? premium.adjustment_amount : 0,
        beneficaryId: beneficaryId,
        effective_date: effectiveDate ? effectiveDate : formatedTodayData(),
        issuing_fees:
          premium && premium.issuing_fees ? premium.issuing_fees : 0,
        // : moment().format("YYYY-MM-DD"),
      })
      // setPremium(response?.item)
      setPremium({
        ...response.item,
        discount_amount: response.item.discount_amount.toFixed(2),
        // issuing_fees: "",
        sub_total:
          response.item.price +
          response.item.loading_amount +
          response.item.tpa_fees_amount +
          response.item.commission_amount +
          response.item.other_amount,
      })
      NotificationMessage("success", "Calculated Successfully")
      setError("")
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  // Index Premium

  const getBeneficiaryPremiums = async beneficaryId => {
    setIsLoading(true)
    try {
      const response = await getPremiums(beneficaryId, { has_children: 1 })
      setTableData(response?.item)
      setError("")
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      setError(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (beneficaryId && !editable) {
      getBeneficiaryPremiums(beneficaryId)
    }
  }, [beneficaryId])

  // round float numbers
  const roundNumber = number => {
    if (isNull(number)) return 0
    else {
      if (isInteger(number)) {
        return +number
      } else {
        return +number.toFixed(2)
      }
    }
  }

  return (
    <FormModal
      show={isPremiumModal}
      onCloseClick={() => {
        setIsPremiumModal(false)
        setError("")
      }}
      //   onSaveClick={validation.handleSubmit}
      onSaveClick={() => {
        editable && handleSavePremium(beneficaryId)
      }}
      includeComputeButton={editable ? true : false}
      onComputeClick={() => calculateBeneficiaryPremiums(beneficaryId)}
      hideSaveButton={!premium ? true : false}
      modalTitle={t("Premium Calcualtion")}
      isSaving={editable ? (isSaving || discountError ? true : false) : true}
      modalSize="xl"
      noFooter={!editable}
      notCentered={true}
    >
      {error && <Alert color="danger">{error}</Alert>}
      {editable && (
        <DatePickerInput
          label={t("Effective Date")}
          value={effectiveDate}
          onChange={(date, dateStr, instance) => setEffectiveDate(dateStr)}
          onBlur={() => console.log("blured")}
          // disableFutureDates={false}
          removeDisable={true}
          minDate={policy?.start_date}
          maxDate={policy?.stop_date}
        />
      )}
      {/* Table */}
      <div
        className="table-responsive react-table mt-3"
        style={{ overflow: "auto", display: "block" }}
      >
        <table className="table table-hover" style={{ position: "relative" }}>
          <thead
            className="table-light table-nowrap sticky-header"
            id="__FPSC_ID_1_1683283486344"
          >
            <tr>
              {tableHeader.map((header, index) => (
                <th key={header}>
                  {header}
                  {index === 0 ? "" : policy?.currency?.symbol}
                </th>
              ))}
            </tr>
          </thead>
          {isLoading ? (
            <tbody>
              <tr>
                <td colSpan={tableHeader?.length}>
                  <div
                    style={{
                      position: "absolute",
                      left: "0",
                      right: "0",
                    }}
                  >
                    <TableSkeleton />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {!editable &&
                tableData.length > 0 &&
                tableData.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.beneficiary?.name}</td>
                    <td>{roundNumber(item?.price)}</td>
                    {/* SubTotal */}
                    <td>
                      {roundNumber(
                        item?.price +
                          item?.loading_amount +
                          item?.tpa_fees_amount +
                          item?.commission_amount +
                          item?.other_amount
                      )}
                    </td>
                    <td>{roundNumber(item?.discount_amount)}</td>
                    <td>{roundNumber(item?.issuing_fees)}</td>
                    <td>{roundNumber(item?.adjustment_amount)}</td>
                    <td>{roundNumber(item?.tax_amount)}</td>
                    <td>{roundNumber(item?.total_price)}</td>
                  </tr>
                ))}
              {editable && premium && (
                <tr>
                  <td>{roundNumber(premium?.price)}</td>
                  <td>{roundNumber(premium.sub_total || 0)}</td>
                  {/* discount amount */}
                  <td>
                    <input
                      id=""
                      name=""
                      type="number"
                      min="0"
                      className={`editable-table-input ${
                        discountError ? "border-danger" : ""
                      }`}
                      value={premium.discount_amount}
                      onChange={e => {
                        const numValue = e.target.valueAsNumber
                        setPremium({
                          ...premium,
                          discount_amount:
                            numValue < 0 ? numValue * -1 : numValue,
                        })

                        if (numValue > roundNumber(premium?.loading_amount)) {
                          setDiscountError(
                            `Discount Cann’t be greater than ${roundNumber(
                              premium?.loading_amount
                            )}`
                          )
                        } else {
                          setDiscountError("")
                        }
                      }}
                    />
                    {discountError ? (
                      <span className="text-danger font-size-10">
                        {discountError}
                      </span>
                    ) : null}
                  </td>

                  {/* Adjustment amount */}
                  <td>
                    <input
                      id=""
                      name=""
                      type="number"
                      min="0"
                      className="editable-table-input"
                      value={premium.adjustment_amount}
                      onChange={e => {
                        setPremium({
                          ...premium,
                          adjustment_amount:
                            e.target.valueAsNumber < 0
                              ? e.target.valueAsNumber * -1
                              : e.target.valueAsNumber,
                        })
                      }}
                    />
                  </td>

                  {/* Issuing Fees */}
                  <td>
                    <input
                      id=""
                      name=""
                      type="number"
                      min="0"
                      className="editable-table-input"
                      value={premium.issuing_fees}
                      onChange={e =>
                        setPremium({
                          ...premium,
                          issuing_fees:
                            e.target.valueAsNumber < 0
                              ? e.target.valueAsNumber * -1
                              : e.target.valueAsNumber,
                        })
                      }
                    />
                  </td>

                  {/* Vat */}
                  <td>{premium.tax_amount || 0}</td>
                  {/* total  => (subtotal + VAT) - discount */}
                  <td>
                    {premium.tax_amount
                      ? (
                          premium.sub_total +
                          premium.adjustment_amount +
                          premium.issuing_fees +
                          premium.tax_amount -
                          premium.discount_amount
                        ).toFixed(2)
                      : (
                          premium.sub_total +
                          premium.adjustment_amount +
                          premium.issuing_fees +
                          0 -
                          premium.discount_amount
                        ).toFixed(2)}
                  </td>
                </tr>
              )}
              {!editable && (
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    {roundNumber(
                      tableData.reduce((total, item) => total + item.price, 0)
                    )}
                  </td>

                  {/* Sub Total */}
                  <td>
                    {roundNumber(
                      tableData.reduce(
                        (total, item) =>
                          total +
                          item?.price +
                          item?.loading_amount +
                          item?.tpa_fees_amount +
                          item?.commission_amount +
                          item?.other_amount,

                        0
                      )
                    )}
                  </td>
                  <td>
                    {roundNumber(
                      tableData.reduce(
                        (total, item) => total + item.discount_amount,
                        0
                      )
                    )}
                  </td>
                  <td>
                    {roundNumber(
                      tableData.reduce(
                        (total, item) => total + item.issuing_fees,
                        0
                      )
                    )}
                  </td>
                  <td>
                    {roundNumber(
                      tableData.reduce(
                        (total, item) => total + item.adjustment_amount,
                        0
                      )
                    )}
                  </td>

                  {/* VaT */}
                  <td>
                    {isNaN(
                      tableData.reduce(
                        (total, item) => total + item.tax_amount,
                        0
                      )
                    )
                      ? 0
                      : roundNumber(
                          tableData.reduce(
                            (total, item) => total + item.tax_amount,
                            0
                          )
                        )}
                  </td>
                  {/* Total Price */}
                  <td>
                    {roundNumber(
                      tableData.reduce(
                        (total, item) => total + item.total_price,
                        0
                      )
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
    </FormModal>
  )
}

export default withTranslation()(PremiumModal)
