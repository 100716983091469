import * as url from "../url_helper"
import { request } from "../api_helper"

export const getConversations = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  resource_id,
  module_id,
  access_type_id,
  ...rest
}) =>
  request({
    url: url.CHAT,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      // sort_by: sort_by ? sort_by : "id",
      // sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      resource_id,
      module_id,
      access_type_id,
      ...rest,
    },
  })

export const showConverstation = (conversationId) =>
  request({ url: `${url.CHAT}/${conversationId}`, method: "GET" })

export const addConversation = (data) =>
  request({ url: `${url.CHAT}`, method: "POST", data })

export const updateConversation = (conversationId, updatedData) =>
  request({
    url: `${url.CHAT}/${conversationId}`,
    method: "PUT",
    data: updatedData,
  })

export const AddMessage = (conversationId, data) =>
  request({
    url: `${url.CHAT}/${conversationId}/messages`,
    method: "POST",
    data,
  })
