import React, { Fragment, useEffect, useRef, useState } from "react";
import { Label, UncontrolledTooltip } from "reactstrap";
import AsyncSelect from "react-select/async";
import FormInputError from "./FormInputError";
import { apiErrorrHandler, isItemObject, NotificationMessage } from "utils";
import { useLocation } from "react-router-dom";
import DropdownChangeModal from "../Modals/DropdownChangeModal";
import { has, omit, pick, set } from "lodash";

const AsyncSearchSelect = ({
  id,
  name,
  value: propValue,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  options,
  onChange,
  customOnChange,
  disableSearch,
  searchKey,
  searchParam,
  onBlur,
  invalid,
  error,
  getOptionsData,
  onSelectData,
  showPopupOnChange,
  urlAddOns,
  dynamicQuery,
  mappingLabel,
  mappingValue,
  wrapperClassNames,
  horizontalLayout,
  fixedMenu,
  multiple,
  noLabel,
  generateCustomLabels,
  hideSelectedOptions,
  recordId,
  onClear,
  disableSorting,
  formatOptionLabel,
  generateDisabledOptions,
  fetchAsync,
  defaultEmpty,
  ORSearch,
  labelAddOns,
  validateOnSelect,
  onSelectValidateData,
  inList,
  ...rest
}) => {
  const location = useLocation();
  const { pathname } = location;
  // to know if the page link has a view word or not
  const pageType = pathname.split("/")[2];

  const selectRef = useRef(null);

  // Local States
  const [selectOptions, setSelectOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputvalue] = useState("");

  const inputLabel =
    inputField && inputField.label_name ? inputField.label_name : propLabel;
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder;
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ""
  } ${propLabelClass ? propLabelClass : ""}`;

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ""
  } ${propInputClass ? propInputClass : ""}`;

  // handle input change event
  const handleInputChange = (value) => {
    setInputvalue(value);
  };

  const handleChange = (selectedValue, { action }) => {
    if (validateOnSelect && onSelectValidateData) {
      onSelectValidateData(name, selectedValue);
    }
    if (action === "clear" && onClear) {
      onClear();
    }
    if (multiple) {
      onChange(
        `${name}`,
        selectedValue.length > 0
          ? selectedValue.map((item) => item[mappingValue])
          : ""
      );
    } else {
      onChange(`${name}`, selectedValue ? selectedValue[mappingValue] : "");
      if (onSelectData) {
        onSelectData(selectedValue);
      }
    }
  };

  const handleBlur = () => {
    onBlur(`${name}`, true);
  };

  // load options using API call
  const loadOptions = async (inputValue) => {
    const newURLAddons = omit(urlAddOns, ["search_keys", "search_values"]);
    const newDynamicURLAddons = omit(dynamicQuery, [
      "search_keys",
      "search_values",
    ]);
    const propSearchKeys = has(urlAddOns, "search_keys")
      ? pick(urlAddOns, ["search_keys"])
      : has(dynamicQuery, "search_keys")
      ? pick(dynamicQuery, ["search_keys"])
      : "";
    const propSearchValues = has(urlAddOns, "search_values")
      ? pick(urlAddOns, ["search_values"])
      : has(dynamicQuery, "search_values")
      ? pick(dynamicQuery, ["search_values"])
      : "";

    const newSearchKeys = searchKey
      ? [...Object.values(propSearchKeys), searchKey].join(",")
      : [...Object.values(propSearchKeys)].join(",");
    const newSearchValues = searchKey
      ? [...Object.values(propSearchValues), inputValue].join(",")
      : [...Object.values(propSearchValues)].join(",");

    let customSearch = {};
    searchParam
      ? set(customSearch, searchParam, inputValue)
      : (customSearch = {});
    // don’t fire the endpoint if the input field is below 3 charac
    if (inputValue.length < 3) return;

    let basicParams = {
      // sort_type: "asc",
      // sort_by: mappingLabel,
      //   per_page: 1000,
      ...newDynamicURLAddons,
      ...newURLAddons,
      sort_type: !disableSorting ? "desc" : "",
      sort_by: !disableSorting ? mappingValue : "",

      search_keys: newSearchKeys,
      search_values: newSearchValues,
      // search_keys: searchKey ? searchKey : "",
      // search_values: searchKey ? (inputValue ? inputValue : "null") : "",
      ...customSearch,
    };

    basicParams = ORSearch
      ? { ...basicParams, search_value: inputValue }
      : basicParams;
    let res;
    try {
      setIsLoading(true);
      if (recordId) {
        res = await getOptionsData(recordId);
      } else {
        res = await getOptionsData(basicParams);
      }
      if (res) {
        if (res.items) {
          return res.items;
        }
        if (res.item) {
          return res.item;
        }
      }
    } catch (error) {
      console.log(error);
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    let res;
    try {
      if (recordId) {
        res = await getOptionsData(recordId);
      } else {
        res = await getOptionsData({
          sort_type: !disableSorting ? "desc" : "",
          sort_by: !disableSorting ? mappingValue : "",
          per_page: 25,
          ...urlAddOns,
          ...dynamicQuery,
        });
      }
      if (res) {
        if (res.items) {
          setSelectOptions(
            inputField?.predefined_value ? res.items.children : res.items
          );
        }
        if (res.item) {
          setSelectOptions(
            inputField?.predefined_value ? res.item.children : res.item
          );
        }
      } else {
        setSelectOptions([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
      setIsLoading(false);
    }
  };

  // for fetching on page load
  useEffect(() => {
    if (
      !fetchAsync &&
      !defaultEmpty &&
      getOptionsData &&
      (mappingLabel || generateCustomLabels) &&
      mappingValue
    ) {
      getData();
    }
  }, [JSON.stringify(dynamicQuery), recordId, fetchAsync, defaultEmpty]);

  // for fetchiing async
  useEffect(() => {
    if (
      fetchAsync &&
      isOpen &&
      getOptionsData &&
      (mappingLabel || generateCustomLabels) &&
      mappingValue
    ) {
      getData();
    }
  }, [JSON.stringify(dynamicQuery), recordId, fetchAsync, isOpen]);

  return (
    <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
      {!noLabel && (
        <div className="d-flex align-items-start gap-2">
          <Label
            htmlFor={id}
            className={`text-capitalize ${
              inputField?.is_required ? "required-label" : ""
            } ${labelClass ? labelClass : ""}`}
            id={`tooltip-${id}`}
          >
            {inputLabel}
          </Label>
          {placeholder && (
            <UncontrolledTooltip placement="top" target={`tooltip-${id}`}>
              {placeholder}
            </UncontrolledTooltip>
          )}

          {pageType !== "view" && (
            <Fragment>{labelAddOns && labelAddOns}</Fragment>
          )}
        </div>
      )}

      <div className={horizontalLayout && inputClass}>
        {/* {isLoading ? (
          <DropDownSkeleton />
        ) : ( */}
        <AsyncSelect
          ref={selectRef}
          onInputChange={handleInputChange}
          cacheOptions
          defaultOptions={selectOptions}
          loadOptions={loadOptions}
          name={name}
          id={id}
          placeholder={pageType === "view" ? "" : placeholder}
          getOptionLabel={(e) =>
            generateCustomLabels ? generateCustomLabels(e) : e[mappingLabel]
          }
          getOptionValue={(e) => e[mappingValue]}
          // disable search based on porp or in view page
          isSearchable={disableSearch || pageType === "view" ? false : true}
          value={
            isItemObject(propValue)
              ? propValue
              : selectOptions.find(
                  (option) => option[mappingValue] === propValue
                )
          }
          onChange={customOnChange ? customOnChange : handleChange}
          onBlur={handleBlur}
          // options={options ? options : selectOptions}
          {...rest}
          className={`select2-selection form-control p-0 ${
            invalid ? "border-danger" : ""
          }${inputClass ? inputClass : ""}${
            pageType === "view" ? "border-0" : ""
          }`}
          // overide the default style
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: 0,
              // This line disable the blue border
              boxShadow: "none",
              minHeight: inList ? "25px" : "30px",
              height: inList ? "25px" : "30px",
            }),

            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            menu: (provided) => ({
              ...provided,
              // if this component in the list page
              // like column filter make the width auto else make it 100%
              width: inList ? "auto" : "100%",
              zIndex: 9999,
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              height: "100%",
            }),
          }}
          menuPosition={fixedMenu && "fixed"}
          // menuShouldBlockScroll={true}
          // menuPosition="fixed"
          // disable clear option in view page
          isClearable={pageType !== "view"}
          // hide the menu,sperator and dropdown icon in view page
          components={
            pageType === "view"
              ? {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  Menu: () => null,
                }
              : {
                  IndicatorSeparator: () => null,
                }
          }
          isLoading={isLoading ? true : false}
          // multiple selection
          isMulti={multiple}
          hideSelectedOptions={hideSelectedOptions}
          isOptionDisabled={(option) =>
            generateDisabledOptions && generateDisabledOptions(option)
          }
        />
        {/* )} */}
        {/* Error Message */}
        {invalid && !isLoading && pageType !== "view" ? (
          <FormInputError error={error} selectError={true} />
        ) : null}
      </div>

      {/* Modal that show up when user want to select another option from select menu */}
      {isWarningModal && showPopupOnChange && (
        <DropdownChangeModal
          show={isWarningModal && showPopupOnChange}
          onCloseClick={() => {
            setIsWarningModal(false);
          }}
        />
      )}
    </div>
  );
};

export default AsyncSearchSelect;
