import React from "react"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
const SingleRowAction = ({
  to,
  onClick,
  record,
  actionName,
  color,
  iconClass,
  target,
}) => {
  return (
    <Link
      to={to ? to : "#"}
      className={color ? color : "text-secondary"}
      onClick={onClick ? onClick : () => {}}
      target={target ? target : "_self"}
    >
      <i
        className={`${iconClass} font-size-18`}
        id={`${actionName.replaceAll(" ", "")}tooltip${record?.id}`}
      />
      <UncontrolledTooltip
        placement="top"
        target={`${actionName.replaceAll(" ", "")}tooltip${record?.id}`}
      >
        {actionName}
      </UncontrolledTooltip>
    </Link>
  )
}

export default SingleRowAction
