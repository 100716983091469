/* Paths */
import { claimPaths } from "common/data/routePaths"
import ChatApp from "pages/Claims/ClaimForm/Conversation/ChatApp"
import { lazy } from "react"

const BatchClaim = lazy(() => import("pages/Claims/BatchClaim"))
const AddBatchClaimGroup = lazy(() =>
  import("pages/Claims/BatchClaim/AddBatchClaimGroup")
)
const BatchClaimForms = lazy(() =>
  import("pages/Claims/BatchClaim/BatchClaimForms")
)
const BatchClaimPdf = lazy(() =>
  import("pages/Claims/BatchClaim/BatchClaimPrint/BatchClaimpdf")
)
const ChronicForm = lazy(() => import("pages/Claims/ChronicForm"))
const Forms = lazy(() => import("pages/Claims/ChronicForm/Forms"))

/*
Components
*/
// claim form
const ClaimForm = lazy(() => import("pages/Claims/ClaimForm"))
// claim proceesing
const ClaimProcessing = lazy(() => import("pages/Claims/ClaimProcessing/index"))
const ClaimPrint = lazy(() => import("pages/Claims/ClaimForm/ClaimPrint"))
const SearchResults = lazy(() => import("pages/Claims/ClaimForm/SearchResults"))

// Add Claim
const AddClaimForm = lazy(() => import("pages/Claims/ClaimForm/Tabs"))
// claim Validation
const ClaimValidation = lazy(() => import("pages/Claims/ClaimValidation"))
// claim view
const ClaimView = lazy(() => import("pages/Claims/ClaimView"))

const {
  claimBatch,
  claimForm: claimFormPath,
  reinmbursementClaim,
  claimProcessing,
} = claimPaths
export const ClaimRoutes = [
  // Claim Form
  { path: claimFormPath, component: ClaimForm },
  // Claim Processing
  { path: claimProcessing, component: ClaimProcessing },
  // Add Claim
  { path: `${claimFormPath}/add`, component: AddClaimForm },
  // Chat
  { path: `${claimFormPath}/chat/:resourceId`, component: ChatApp },
  // Updated Claim
  { path: `${claimFormPath}/edit/:claimId`, component: AddClaimForm },
  // Processing Claim
  { path: `${claimFormPath}/processing/:claimId`, component: AddClaimForm },
  // search results Claim
  { path: `${claimFormPath}/search-results`, component: SearchResults },
  // print Claim
  {
    path: `${claimFormPath}/print/:claimId`,
    component: ClaimPrint,
  },
  // view Claim
  {
    path: `${claimFormPath}/view/:claimId`,
    component: AddClaimForm,
  },

  // Reinmbursement Claim
  { path: reinmbursementClaim, component: ClaimForm },

  // Add Claim
  { path: `${reinmbursementClaim}/add`, component: AddClaimForm },
  // Updated Claim
  { path: `${reinmbursementClaim}/edit/:claimId`, component: AddClaimForm },
  // Processing Claim
  {
    path: `${reinmbursementClaim}/processing/:claimId`,
    component: AddClaimForm,
  },

  // Claim Validation
  { path: claimPaths.claimValidation, component: ClaimValidation },
  // Claim view
  { path: claimPaths.claimView, component: ClaimView },

  // Batch Claim
  { path: claimBatch, component: BatchClaim },
  // Add Batch Claim
  { path: `${claimBatch}/add`, component: BatchClaimForms },
  // Add Batch Claim Group
  { path: `${claimBatch}/group`, component: AddBatchClaimGroup },
  // Edit Batch Claim
  { path: `${claimBatch}/edit/:batchClaimId`, component: BatchClaimForms },
  // View Batch Claim
  { path: `${claimBatch}/view/:batchClaimId`, component: BatchClaimForms },
  // Print Receipt
  { path: `${claimBatch}/print/:id`, component: BatchClaimPdf },

  /* Chronic Form */

  { path: claimPaths.chronicForm, component: ChronicForm },
  // Add Chronic
  { path: `${claimPaths.chronicForm}/add`, component: Forms },
  // Edit Chronic
  {
    path: `${claimPaths.chronicForm}/edit/:chronicId`,
    component: Forms,
  },
]
