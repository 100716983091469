import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next";

import PageBreadCrumbAndTitle from "components/TP/Common/General/PageBreadCrumbAndTitle";
import LineApexChart from "components/TP/Charts/LineApexChart";
import LineColumnApexChart from "components/TP/Charts/LineColumnApexChart";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { dashboardsID, mainDashboardID } from "utils/modulesIds";
import ReactApexChart from "react-apexcharts";
import LineChart from "./LineChart";

const MotorDashboard = (props) => {
  const { module } = useSelector((state) => ({
    module: state.Sidebar?.modules
      ?.find((item) => item.id === dashboardsID)
      ?.sub_modules?.find((item) => item.id === mainDashboardID),
    loading: state.Sidebar?.loading,
  }));
  useEffect(() => {
    document.title = "Motor Dashboard - 2024";
  }, []);
  const reports = [
    {
      title: "TPL <br/> Loss Ratio",
      value: "110",
      badgeValue: "+ 1.2%",
      dir: "up",
      color: "yellowOrange",
      desc: "From Last Year",
    },
    {
      title: "Body Injury <br/> Loss Ratio",
      value: "78",
      badgeValue: "+ 9%",
      dir: "down",
      color: "success",
      desc: "From Last Year",
    },
    {
      title: "Comprehensive <br/> Loss Ratio",
      value: "96",
      badgeValue: "+ 0.3%",
      color: "success",
      dir: "down",
      desc: "From Last Year",
    },
    {
      title: "TPL <br/> Retention Ratio",
      value: "20",
      badgeValue: "+ 0.3%",
      color: "success",
      dir: "up",
      desc: "From Last Year",
    },
    {
      title: "Body <br/> Retention Ratio",
      value: "80",
      badgeValue: "+ 0.3%",
      color: "success",
      dir: "up",
      desc: "From Last Year",
    },
    {
      title: "Comprehensive  <br/> Retention Ratio",
      value: "96",
      badgeValue: "+ 2.3%",
      color: "yellowOrange",
      dir: "down",
      desc: "From Last Year",
    },
  ];

  //claim boxes
  const claimsCountData = [
    { label: "In-Patient", value: "1,650" },
    { label: "Pharmacy", value: 200 },
    { label: "Doctor Visits", value: 312 },
    { label: "Ambulatory", value: 800 },
  ];
  const claimsAmountData = [
    { label: "In-Patient", value: "$ 62,000" },
    { label: "Pharmacy", value: "$ 1,500" },
    { label: "Doctor Visits", value: "$ 2,600" },
    { label: "Ambulatory", value: "$ 20,000" },
  ];

  const ExpirePoliciesData = [4, 9, 16];
  const ActivePoliciesData = [4723, 820, 1690];

  const options = {
    chart: {
      width: "100%",
      height: "400px",
      type: "pie",
    },
    labels: ["TPL", "Body Injury", "Comprehensive"],
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 150,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },

    colors: ["#556ee6", "#f1b44c", "#f46a6a", "#50a5f1", "#34c38f"],
  };

  //Vehicle Categries
  const VehiclesData = [
    {
      name: "",
      data: [33.1, 52.002, 61.0, 62.429, 67.748, 74.789, 80.236, 91.051],
    },
  ];
  const VehiclesOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#00BDFF"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [
        "Midsize Cars",
        "Luxury Cars",
        "Sports Cars",
        "Crossover SUVs",
        "Light Trucks",
        "Minivans",
        "Sport Bikes",
        "Delivery Vans",
      ],
    },
  };

  //claim overview
  const stackedColumnsoptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded",
      },
    },

    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },
    colors: ["#556ee6", "#F1B44C", "#34C38F"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  };
  const stackedColumnData = [
    {
      name: "PM",
      data: [5000, 15000, 4000, 24000, 7500, 12000, 11000, 8000, 27000, 5000],
    },
    {
      name: "AMB",
      data: [5000, 10000, 7500, 2000, 5000, 10000, 7000, 10000, 5000, 5000],
    },
    {
      name: "IN",
      data: [8000, 15000, 7000, 6500, 12000, 22000, 6000, 7000, 8000, 5000],
    },
  ];

  //vehicle make year
  const Vehiclesseries = [
    {
      name: "",
      type: "column",
      data: [84, 87, 50, 36, 54, 87, 39, 74, 28, 48, 30],
    },
  ];

  const VehicleOptions = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
      offsetY: 10,
    },
    stroke: {
      width: [0, 0, 2, 2],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
      },
      column: {
        tooltip: null,
      },
    },
    fill: {
      opacity: [1, 1, 0.1, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: [
      "2014",
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
    ],
    colors: ["#556ee6"],
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      tickAmount: 11,
    },
    tooltip: {
      shared: false,
      intersect: false,
      x: {
        show: false,
        format: "",
      },
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return "$" + y.toFixed(0) + "K";
          }
          return y;
        },
      },
    },
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageBreadCrumbAndTitle
            pageType="ddd"
            title="Motor Dashboard - 2024"
          />
          {/* Rounded Corners White Card and Table inside it */}
          <Row>
            {reports.map((report) => (
              <Col md={2} key={report.title}>
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-center mb-3">
                      <h6>{parse(report.title)}</h6>
                    </div>
                    <div className="text-muted mt-3">
                      <h4>
                        {report.value}%
                        <i
                          className={
                            "mdi mdi-chevron-" +
                            report.dir +
                            " ms-1 text-" +
                            report.color
                          }
                        />
                      </h4>
                      <div className="d-flex">
                        <span
                          className={
                            "badge badge-soft-" + report.color + " font-size-12"
                          }
                        >
                          {" "}
                          {report.badgeValue}{" "}
                        </span>{" "}
                        <span className="ms-2 text-truncate">
                          {report.desc}
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="mb-4 card-title">Portfolio Overview</div>
                  <LineApexChart
                    dataColors='["--bs-blue", "--bs-green"]'
                    series={[
                      {
                        name: "New Policy",
                        data: [26, 24, 32, 36, 33, 31, 33],
                      },
                      {
                        name: "Lost Policy",
                        data: [14, 11, 16, 12, 17, 13, 12],
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div className="card-title mb-4">Business Overview </div>
                  <LineColumnApexChart dataColors='["--bs-danger","--bs-blue", "--bs-green"]' />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {/* <Col md={3}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Claims Count</CardTitle>
                  <div className="container">
                    <Row className="d-flex justify-content-center">
                      <Col md={8}>
                        <div className="mb-4 text-center">
                          <div className="mb-2 align-self-center ">
                            <i
                              style={{
                                fontSize: "50px",
                                color: "#766ee0",
                              }}
                              className="bx bx-shield-quarter "
                            ></i>
                          </div>
                          <div className="mb-2 font-size-18 fw-bold ">
                            2,836
                          </div>
                          <div className=" text-muted mb-2">Claims</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {claimsCountData.map((item) => (
                          <div
                            key={item.label}
                            className="mb-3 d-flex justify-content-between"
                          >
                            <div>{item.label}</div>
                            <div className="font-size-15 fw-bold">
                              {item.value}
                            </div>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Claims Amount</CardTitle>
                  <div className="container">
                    <Row className="d-flex justify-content-center">
                      <Col md={8}>
                        <div className="mb-4 text-center">
                          <div className="mb-2 align-self-center ">
                            <i
                              style={{
                                fontSize: "50px",
                                color: "#48bea1",
                              }}
                              className="bx bx-shield-quarter"
                            ></i>
                          </div>
                          <div className="mb-2 font-size-18 fw-bold ">
                            $ 85,000
                          </div>
                          <div className=" text-muted mb-2">Claims</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {claimsAmountData.map((item) => (
                          <div
                            key={item.label}
                            className="mb-3 d-flex justify-content-between"
                          >
                            <div>{item.label}</div>
                            <div className="font-size-15 fw-bold">
                              {item.value}
                            </div>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            {/* <Col md={3} className="d-flex">
              <Card className="flex-grow-1">
                <CardBody>
                  <h4 className="card-title mb-5">Due To Expire Policies</h4>
                  <div className="mb-5">
                    <ReactApexChart
                      type="pie"
                      height="350px"
                      width="342px"
                      options={options}
                      series={ExpirePoliciesData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Active Policies</h4>
                  <div className="mt-2">
                    <ReactApexChart
                      className="d-flex justify-content-center align-items-center"
                      type="pie"
                      height="345px"
                      width="350px"
                      options={options}
                      series={ActivePoliciesData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title">
                    Distribution Channels Performance
                  </h4>
                  <Row className="justify-content-center mt-4">
                    <Col sm={4}>
                      <div className="text-center">
                        <h5 className="mb-0">$ 26,541</h5>
                        <p className="text-muted te,xt-truncate">Direct</p>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="text-center">
                        <h5 className="mb-0">$ 64,541</h5>
                        <p className="text-muted text-truncate">Brokers</p>
                      </div>
                    </Col>
                  </Row>
                  <LineChart dataColors='["--bs-primary-rgb, 0.2", "#556ee6", "--bs-light-rgb, 0.2", "--bs-light"]' />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title">
                    Premium By Vehicle Year Of Make
                  </h4>
                  <div>
                    <ReactApexChart
                      options={VehicleOptions}
                      series={Vehiclesseries}
                      type="line"
                      height="350"
                      className="apex-charts pb-3"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Premium By Vehicle categories</h4>
                  <div>
                    <ReactApexChart
                      options={VehiclesOptions}
                      series={VehiclesData}
                      type="bar"
                      height="350"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

MotorDashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(MotorDashboard);
