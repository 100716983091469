import { claimPaths } from "./claimRoutePaths"
import { commonDataPaths } from "./commonDataRoutePaths"
import { stakeholderPaths } from "./stakeholderRoutePaths"
import { networkPaths } from "./networkRoutePaths"
import { productionPaths } from "./productionRoutePaths"
import { financePaths } from "./financeRoutePaths"
import { settingsPaths } from "./settingsRoutePaths"
import { dashboardPaths } from "./dashboardRoutePaths"
import { generalPaths } from "./generalRoutePaths"
import { reportPaths } from "./reportRoutePatch"

export const motorPaths = {
  workShops: "/motor_providers",
  motorNetworks: "/moto-networks",
  agentMotoCommissions: "/agent-moto-commissions",
  motoInsured: "/moto-insureds",
  motoPolicies: "/moto-policies",
  motoProducts: "/moto-products",
  motoExperts: "/motor-experts",
  fnolForm: "/fnol-form",
  fnolValidation: "/fnol-verification",
  motoTOB: "/motor-tob-template",
  motorDrivers: "/drivers",
  motorVehicles: "/vehicles",
}
export {
  claimPaths,
  commonDataPaths,
  stakeholderPaths,
  networkPaths,
  productionPaths,
  financePaths,
  settingsPaths,
  dashboardPaths,
  generalPaths,
  reportPaths,
}
